import { inject, Pipe, PipeTransform } from '@angular/core';
import { ProfileService } from '@services/profile/profile.service';
import { APP_LOCALES } from '@constants/app-locales';

@Pipe({
  name: 'dynamicDateTimeFormat',
  standalone: true,
  pure: false,
})
export class DynamicDateTimeFormatPipe implements PipeTransform {
  static FORMAT_FOR_TODAY: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  static FORMAT_FOR_THIS_YEAR: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  static FORMAT_FOR_OTHERS: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  static format(value: string | Date | number, locale?: (typeof APP_LOCALES)[number]['fullCode']): string {
    const today = new Date();
    const date = new Date(value);

    let format = this.FORMAT_FOR_OTHERS;

    if (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    ) {
      format = this.FORMAT_FOR_TODAY;
    } else if (date.getFullYear() === today.getFullYear()) {
      format = this.FORMAT_FOR_THIS_YEAR;
    }

    return new Intl.DateTimeFormat(locale, format).format(date);
  }

  protected profile = inject(ProfileService);

  transform(value: string | Date | number): string {
    const locale = this.profile.localeFullCode();
    return DynamicDateTimeFormatPipe.format(value, locale);
  }
}

@Pipe({
  name: 'dateTimeFormat',
  standalone: true,
  pure: false,
})
export class DateTimeFormatPipe implements PipeTransform {
  protected profile = inject(ProfileService);

  static format(
    value: string | Date | number,
    locale?: (typeof APP_LOCALES)[number]['fullCode'],
    options: Intl.DateTimeFormatOptions = {
      dateStyle: 'medium',
      timeStyle: 'short',
    },
  ): string {
    const date = new Date(value);
    return new Intl.DateTimeFormat(locale, options).format(date);
  }

  transform(
    value: string | Date | number,
    options: Intl.DateTimeFormatOptions = {
      dateStyle: 'medium',
      timeStyle: 'short',
    },
  ): string {
    const locale = this.profile.localeFullCode();
    return DateTimeFormatPipe.format(value, locale, options);
  }
}

@Pipe({
  name: 'dateFormat',
  standalone: true,
  pure: false,
})
export class DateFormatPipe implements PipeTransform {
  protected profile = inject(ProfileService);

  static format(
    value: string | Date | number,
    locale?: (typeof APP_LOCALES)[number]['fullCode'],
    format: Intl.DateTimeFormatOptions['dateStyle'] = 'medium',
  ): string {
    const date = new Date(value);

    return new Intl.DateTimeFormat(locale, {
      dateStyle: format,
    }).format(date);
  }

  transform(value: string | Date | number, format: Intl.DateTimeFormatOptions['dateStyle'] = 'medium'): string {
    const locale = this.profile.localeFullCode();
    return DateFormatPipe.format(value, locale, format);
  }
}

@Pipe({
  name: 'timeFormat',
  standalone: true,
  pure: false,
})
export class TimeFormatPipe implements PipeTransform {
  protected profile = inject(ProfileService);

  static format(
    value: string | Date | number,
    locale?: (typeof APP_LOCALES)[number]['fullCode'],
    format: Intl.DateTimeFormatOptions['timeStyle'] = 'short',
  ): string {
    const date = new Date(value);

    return new Intl.DateTimeFormat(locale, {
      timeStyle: format,
    }).format(date);
  }

  transform(value: string | Date | number, format: Intl.DateTimeFormatOptions['timeStyle'] = 'short'): string {
    const locale = this.profile.localeFullCode();
    return TimeFormatPipe.format(value, locale, format);
  }
}
